'use client'

import { Box } from "@mui/material"
import { styled, keyframes } from '@mui/system';

const HeroWrapper = ({onButtonClick, children, isMobile}) => {

return(<Box 
style={{display: 'flex',
flexDirection: 'column',
justifyContent: 'center',
alignItems: 'center',
padding: 0,
height: isMobile ? 'calc(80vh - 65px)' : 'calc(100vh - 65px)',
width: '100%',
position: 'relative',
textAlign: 'center',
color: 'white',
cursor: 'pointer'
}}
  href='/newpage'
>
    {children}

    </Box>)
    }

export default HeroWrapper

export const ImageHeroWrapper = ({onButtonClick, isMobile, children, isLeft = true, topMargin}) => {
   return <Box style={{ display: 'flex',
    flexDirection: 'column', // Align children vertically
    justifyContent: 'flex-end',
    alignItems: isLeft ? 'flex-start' : 'flex-end',
    padding: 0,
    marginTop: '24px',
    height: isMobile ? '300px' : '470px',
    width: '100%',
    position: 'relative',
    textAlign: 'center',
    color: 'white',
    marginTop: topMargin ? '32px' : 0,
    cursor: 'pointer'}} 
    onClick={onButtonClick}>
        {children}
        </Box>
}
const bouncePencil = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;
const moveStars = keyframes`
0% {
  transform: translateY(0px);
}
50% {
  transform: translateY(10px);
}
100% {
  transform: translateY(0px);
}
`;
export const MovingStar = styled(Box)(() => ({
    position: 'absolute',
    width: '30px',
    height: '30px',
    background: 'yellow',
    borderRadius: '50%',
    animation: `${moveStars} 4s infinite ease-in-out`,
  }));


const BouncingPencil = styled(Box)(() => ({
    position: 'absolute',
    width: '100px',
    height: '100px',
    background: 'url(/pencil.png) no-repeat center center / contain',
    animation: `${bouncePencil} 2s infinite`,
    bottom: '20px', // Adjust position
    right: '20px',  // Adjust position
  }));

  export const Pencil = () => <BouncingPencil sx={{ animation: `${bouncePencil} 3s infinite` }} /> 
